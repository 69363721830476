import React, { Component } from 'react'

import Footer from '../main-components/footer'
import Header from '../main-components/header'




export default class Bhavan extends Component { 


  render() {
  
    return (
      <div className='innerpage bhavanpage'>
        <style>
          {"\
          header a.bhavana, header a.bhavana:hover{\
            background-color: var(--primary-color);\
            color: var(--background-color);\
            text-decoration: none !important;\
          }\
          "}
        </style>
        <Header/>
        <main>
          <div className='container hero'>
            <div className='row'>
              <div className='col-lg-6 cparent'>
                <div className='content'>
                  <span className='subheading'>Vivikta Bhavan</span>
                  <h2>From our kitchen <br/>to your heart.</h2>
                  <p className='r'>At Vivikta Bhavan, we understand the profound role <br className='desk-br'/>that food plays in bringing people together. It is not just <br className='desk-br'/>sustenance; it's an experience. That's why we've <br className='desk-br'/>meticulously curated menus that are a testament to our <br className='desk-br'/>commitment to enhancing your event experience.</p>
                </div> 
              </div>
              <div className='col-lg-6'>
                <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Bhavan-Hero.png' alt='Vivikta Bhavan Store'/>
              </div>
            </div>
          </div>

          <div className='section2 container'>
            <div className='section2-inner'>
            <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam2.png' className='decoration1' alt='Vivikta-Catering-Decoration'/>
            <div className="row">
              <div className='col-lg-6 image'>
                <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Bhavan-Sec2-1.png' alt='Vivikta Bhavan Store'/>
              </div>
              <div className='col-lg-6 content-bpage'>
                <h3>Explore Our <br />Flavorful Offerings</h3>
                <p className='s'>Vivikta Bhavan isn't just a culinary haven; it's a treasure trove of handpicked, authentic South Indian delicacies and an unexpected journey through the tantalizing flavors of Chinese cuisine.</p>
                <p className='s'>What's even better? You can relish these culinary wonders from the comfort of your home. Vivikta Bhavan proudly partners with renowned delivery platforms like Swiggy and Zomato. This collaboration ensures that our carefully curated dishes, whether it's the richness of chapathi or the zest of Chinese flavors, reach you with unparalleled convenience. </p>
                <p className='s'>Our commitment to maintaining the authenticity, quality, and taste extends beyond our kitchen as our partners assure swift and reliable delivery, preserving the freshness and flavors of every dish.</p>
                <div className='two-buttons'>
                <a href='tel:9940557706' target='blank' className='button'>CALL TO ORDER</a>
                <a href='https://www.swiggy.com/restaurants/vivikta-chapati-shop-vadapalani-chennai-139168' target='blank' className='button'>ORDER ON SWIGGY</a>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className='container bpage-map'>
            <div className="row">
              <div className='col-lg-6 content-bpage'>
                <h3>Come & Visit Us</h3>
                <p className='s'>We look forward to welcoming you and providing an experience that will leave you with cherished memories of Indian flavours and hospitality.</p>
                <a href='https://maps.app.goo.gl/ioX9HefgEoKZBEhK6' target='blank' className='button'>GET DIRECTION</a>
              </div>
              <div className='col-lg-6 image'>
                <iframe title='vivikta location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.6731362509232!2d80.20707876954013!3d13.05523190564553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52671d67349d71%3A0x1458b10c383c46ff!2sVivikta%20Chapati%20Shop%20%26%20Vivikta%20Bhavan!5e0!3m2!1sen!2sin!4v1713275390248!5m2!1sen!2sin" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </main>
        <Footer/>
      </div>
    )

  }
}
