import React, { Component } from 'react'

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import emailjs from '@emailjs/browser';




export default class Products extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      images: [
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-RM-MC.png',
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/Masala.png',
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/Ginger.png',
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/Garlic.png',
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/Sweet.png',
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/Ragi.png',
        'https://ik.imagekit.io/vivikta/website/tr:f-auto/Kambu.png'
        // Add more image URLs here
      ],
      readymadename: '',
      readymadenumber: '',
      readymadetype: '',
      readymadepieces: '',
      error: {},
      submitted: false, // Added state to track form submission
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.nextSlide, 3000); // Auto slide every 3 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextSlide = () => {
    const { currentIndex, images } = this.state;
    const newIndex = (currentIndex + 1) % images.length;
    this.setState({ currentIndex: newIndex });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      try {
        const { readymadename, readymadenumber, readymadetype, readymadepieces } = this.state;

        // Create the template parameters for EmailJS
        const templateParams = {
          readymadename,
          readymadenumber,
          readymadetype,
          readymadepieces,
        };

        // Update the state to indicate form submission
        this.setState({ submitted: true });

        // Change the button text to "Sending"
        const submitButton = document.getElementById('readymade-submit-button');
        if (submitButton) {
          submitButton.innerText = 'Sending...';
        }

        // Send the email using EmailJS
        await emailjs.send('service_n61gdke', 'template_xzl1voj', templateParams, 'r64sbh4YTpbJMLt12');
        console.log('Email sent successfully!');
        window.location.href = '/thanks';
        // Update the state to indicate form submission and trigger redirection
        this.setState({ submitted: true });
      } catch (error) {
        console.log('Error sending the form:', error);
      }
    }
  };

  validateForm = () => {
    const { readymadename, readymadenumber, readymadetype, readymadepieces } = this.state;
    let isValid = true;
    let error = {};

    if (!readymadename) {
      isValid = false;
      error.readymadename = 'Name is required';
    }

    if (!readymadenumber) {
      isValid = false;
      error.readymadenumber = 'Phone number is required';
    } else if (!this.validatePhoneNumber(readymadenumber)) {
      isValid = false;
      error.readymadenumber = 'Invalid phone number';
    }

    if (!readymadetype) {
      isValid = false;
      error.readymadetype = 'Event type is required';
    }
    
    if (!readymadepieces) {
      isValid = false;
      error.readymadepieces = 'readymade menu is required';
    }


    this.setState({ error });
    return isValid;
  };


  validatePhoneNumber = (readymadenumber) => {
    // Phone number validation regex pattern
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(readymadenumber);
  };



  render() {

    const { currentIndex, images, readymadename, readymadenumber, readymadetype, readymadepieces, error } = this.state;
  
    return (
      <div className='innerpage productspage'>
        <style>
          {"\
          header a.productsa, header a.productsa:hover{\
            background-color: var(--primary-color);\
            color: var(--background-color);\
            text-decoration: none !important;\
          }\
          "}
        </style>
        <Header/>
        <main>
          <div className='container hero'>
            <div className='row'>
              <div className='col-lg-6 cparent'>
                <div className='content'>
                  <span className='subheading'>Our Products</span>
                  <h2>Easy & quick, discover our <br className='desk-br'/>tasty & nutritious products.</h2>
                  <p className='r'>At Vivikta Readymade food product, we understand that you have a busy lifestyle, and spending hours kneading dough <br className='desk-br'/>or rolling out chapati can be time-consuming. That's why <br className='desk-br'/>we have carefully crafted our ready-to-cook products to <br className='desk-br'/>meet your needs.</p>
                </div> 
              </div>
              <div className='col-lg-6'>
                <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Products-hero.png' alt='Vivikta Bhavan Store'/>
              </div>
            </div>
          </div>

          <div className='section2 container'>
            <div className='section2-inner'>
            <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam2.png' className='decoration1' alt='Vivikta-Catering-Decoration'/>
            <div className="row">
              <div className='col-lg-6 image'>
                <img className='hero-img img-fluid him' src={images[currentIndex]} alt='Vivikta Bhavan Store' />
              </div>
              <div className='col-lg-6 form-side'>
                <h3>Order Enquiry Form</h3>
                <form onSubmit={this.handleSubmit}>
                  <input type="text" name="readymadename" placeholder='Your Name' value={readymadename} onChange={this.handleChange} required/>
                  {error.readymadename && <span className="error">{error.readymadename}</span>}
                  <input type="number" name="readymadenumber" placeholder='Your Phone Number' value={readymadenumber} onChange={this.handleChange} required/>
                  {error.readymadenumber && <span className="error">{error.readymadenumber}</span>}
                  <select id="product_type" name="readymadetype" value={readymadetype} onChange={this.handleChange}>
                    <option value="Normal Chapati">Normal Chapati</option>
                    <option value="Mint Chapati">Mint Chapati</option>
                    <option value="Garlic Chapati">Garlic Chapati</option>
                    <option value="Ginger Chapati">Ginger Chapati</option>
                    <option value="Curry Leaf Chapati">Curry Leaf Chapati</option>
                    <option value="Masala Chapati">Masala Chapati</option>
                    <option value="Drumstick Chapati">Drumstick Chapati</option>
                    <option value="Methi Chapati">Methi Chapati</option>
                    <option value="Ragi Chapati">Ragi Chapati</option>
                    <option value="Kambu Chapati">Kambu Chapati</option>
                    <option value="Horse Gram Chapati">Horse Gram Chapati</option>
                    <option value="Bitter Gourd Chapati">Bitter Gourd Chapati</option>
                    <option value="Multigrain Chapati">Multigrain Chapati</option>
                    <option value="Normal Poori">Normal Poori</option>
                    <option value="Mint Poori">Mint Poori</option>
                    <option value="Curry Leaf Poori">Curry Leaf Poori</option>
                    <option value="Masala Poori">Masala Poori</option>
                    <option value="Methi Poori">Methi Poori</option>
                    <option value="Chola Poori">Chola Poori</option>
                    <option value="Pulka">Pulka</option>
                  </select>
                    {error.readymadetype && <span className="error">{error.readymadetype}</span>}
                    <input type="number" name="readymadepieces" placeholder='No of pieces' value={readymadepieces} onChange={this.handleChange}/>
                    {error.readymadepieces && <span className="error">{error.readymadepieces}</span>}
                    <input type="submit" value="SEND ENQUIRY" className='button' />
                  </form>
              </div>
            </div>
            </div>
          </div>

          {/* <div className='readymade container'>
            <h2>Explore Our Range of Products</h2>
            <div className='card-parent'>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Normal.png' className='card-img' alt='Readymade-Product'/>
                <h4>Normal Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Mint.png' className='card-img' alt='Readymade-Product'/>
                <h4>Mint Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Garlic.png' className='card-img' alt='Readymade-Product'/>
                <h4>Garlic Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Ginger.png' className='card-img' alt='Readymade-Product'/>
                <h4>Ginger Chapati</h4>
              </div>
            </div>
            <div className='card-parent'>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Curry%20leaf.png' className='card-img' alt='Readymade-Product'/>
                <h4>Curry Leaf Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Masala.png' className='card-img' alt='Readymade-Product'/>
                <h4>Masala Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Normal.png' className='card-img' alt='Readymade-Product'/>
                <h4>Drumstick Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Normal.png' className='card-img' alt='Readymade-Product'/>
                <h4>Methi Chapati</h4>
              </div>
            </div>
            <div className='card-parent'>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Ragi.png' className='card-img' alt='Readymade-Product'/>
                <h4>Ragi Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Kambu.png' className='card-img' alt='Readymade-Product'/>
                <h4>Kambu Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Normal.png' className='card-img' alt='Readymade-Product'/>
                <h4>Horse Gram Chapati</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Normal.png' className='card-img' alt='Readymade-Product'/>
                <h4>Bitter Gourd Chapati</h4>
              </div>
            </div>
            <div className='card-parent'>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Sweet.png' className='card-img' alt='Readymade-Product'/>
                <h4>Multigrain Chapathi</h4>
              </div>
            </div>
            
          </div> */}
        </main>
        <Footer/>
      </div>
    )

  }
}
