import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './styles/index.css'
import './styles/style.css'
import Home from './pages/home';
import Products from './pages/products';
import Contact from './pages/contact';
import Bhavan from './pages/bhavan';
import About from './pages/about';
import Catering from './pages/catering';
import Lost from './pages/404';
import Thanks from './pages/thanks';


const root = ReactDOM.createRoot(document.getElementById('onceadev'));
root.render(
  <BrowserRouter basename='/'>
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/our-products' element={<Products />} />
      <Route exact path='/catering' element={<Catering />} />
      <Route exact path='/bhavan' element={<Bhavan />} />
      <Route exact path='/about' element={<About />} />
      <Route exact path='/contact' element={<Contact />} />
      <Route exact path='*' element={<Lost />} />
      <Route exact path='/thanks' element={<Thanks />} />
    </Routes>
  </BrowserRouter>
);