import React, { Component } from 'react'

import Footer from '../main-components/footer'
import Header from '../main-components/header'




export default class About extends Component { 


  render() {
  
    return (
      <div className='innerpage aboutpage'>
        <style>
          {"\
          header a.abouta, header a.abouta:hover{\
            background-color: var(--primary-color);\
            color: var(--background-color);\
            text-decoration: none !important;\
          }\
          "}
        </style>
        <Header/>
        <main>
          <div className='container hero'>
            <div className='row'>
              <div className='col-lg-6 cparent'>
                <div className='content'>
                  <span className='subheading'>Our Story</span>
                  <h2>Pioneering quality and <br className='desk-br'/>affordability in food.</h2>
                  <p className='r'>Since 2017 we started  with a clear goal: to offer really good <br className='desk-br'/>products at prices that make sense for everyone, and we've <br className='desk-br'/>stuck to that promise. Today, we're still all about giving you <br className='desk-br'/>the best quality without compromise.</p>
                </div> 
              </div>
              <div className='col-lg-6'>
                <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-About-Hero.png' alt='Vivikta Bhavan Store'/>
              </div>
            </div>
          </div>
          <div className='section2 container'>
            <div className='section2-inner'>
            <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam.png' className='decoration1' alt='Vivikta-Catering-Decoration'/>
            <div className="row">
              <div className='col-lg-6 image'>
                <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-About-2-1.png' alt='Vivikta Bhavan Store'/>
              </div>
              <div className='col-lg-6 content-bpage'>
                <h3>Our Journey</h3>
                <p className='s'>In 2017, Vivikta embarked on a remarkable journey with a singular goal: to create a business that would provide economically superior food products to both consumers and the food industry. Vivikta's vision was crystal clear: "No mixing, No preservatives." We are determined to offer pure, unadulterated food of the highest quality.</p>
                <p className='s'>With unwavering determination, Vivikta introduced a groundbreaking concept - ready-to-cook chapati and gravy, all bundled together at an incredibly affordable price . This package was so affordable, making it an irresistible choice for individuals and families seeking convenient meal solutions</p>
                <a href='/our-products' className='button'>DISCOVER OUR PRODUCTS</a>
              </div>
            </div>
            <div className="row row2">
              <div className='col-lg-6 content-bpage'>
                <h3>Vivikta Catering Service</h3>
                <p className='s'>By 2018, Vivikta Food Services had become a household name, synonymous with quality and affordability. But Vivikta's vision didn't end there. They knew that catering to customers' needs was a core part of their mission. They encouraged people not only to buy their products for their daily meals but also to consider Vivikta Food Services for catering events and special occasions.</p>
                <p className='s'>Vivikta's journey was a testament to the power of determination and a commitment to serving the heart and soul of India. They wanted to emphasise to every customer that Vivikta Food Services was not just a place to buy food; it was a partner in creating memorable moments, whether for daily meals or special gatherings. </p>
                <a href='/catering' className='button'>EXPLORE CATERING SERVICES</a>
              </div>
              <div className='col-lg-6 image'>
                <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-About-4.png' alt='Vivikta Bhavan Store'/>
              </div>
            </div>
            </div>
          </div>
          <div className='container bpage-map apage-map'>
            <div className="row">
              <div className='col-lg-6 image'>
              <img className='hero-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-About-4-1.png' alt='Vivikta Bhavan Store'/>
              </div>
              <div className='col-lg-6 content-bpage'>
                <h3>About The Founder</h3>
                <p className='s'>Meet Mr.Senthil, Founder and Director of Vivkita, a seasoned IT professional with two decades of experience, embarked on a journey to establish a business of his own. His initial aim was clear: to provide economically superior products to the people and the food industry.</p>
                <p className='s'>Driven by a vision of offering the best quality at affordable prices, he initiated his journey. His commitment was defined by the principle of delivering unparalleled value with a focus on purity and excellence.</p>
                <p className='s'>Mr.Senthil's story is a testament to his determination to create a business that caters to the diverse needs of consumers in the food industry, guided by a passion for quality and affordability.</p>
              </div>
            </div>
          </div>
        </main>
        <Footer/>
      </div>
    )

  }
}
