import React, { Component } from 'react'

import Footer from '../main-components/footer'
import Header from '../main-components/header'




export default class Home extends Component { 


  render() {
  
    return (
      <div className='home'>
        <style>
          {"\
          header a.homea, header a.homea:hover{\
            background-color: var(--primary-color);\
            color: var(--background-color);\
            text-decoration: none !important;\
          }\
          "}
        </style>
        <Header/>
        <main>
          <div className='hero'>
            <div className='hero-container'>
              <img src="https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-banner.png" className="hero-border" alt='Vivikta-Home-decoration'/>
              <h1>Crafting unforgettable moments <br/>with every bite</h1>
              <a href='/catering' className='button'>BOOK YOURSELF IN</a>
              <video poster="https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-Hero-Vwithplay.png" controls id="vid" loop>
                <source src="https://assets.onceadev.com/Vivikta-Hero-Catering.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <div className='section2 catering container'>
            <div className='section2-inner'>
              <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam.png' className='decoration1' alt='Vivikta-Catering-Decoration'/> 
              {/* <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-leaf.png' className='catering-decoration1' alt='Vivikta-Catering-Decoration'/>  */}
              <div className='container content'>
                <span className='subheading'>VIVIKTA CATERING</span>
                <h2>Catering for your <br/>weddings & events</h2>
                <p className='r'>Whether it's a corporate gathering, a family celebration, or <br className='desk-br'/>any special occasion, let us take care of the catering so you <br className='desk-br'/>can focus on enjoying the moment.</p>
                <a href='/catering' className='button'>DISCOVER MORE</a>
              </div>
              <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-veg-catering.png' className='decoration-banana-leaf' alt='Vivikta-Catering-Decoration'/> 
            </div>
          </div>

          <div className="marquee">
            <div className="track">
              <h3 className="content">&nbsp;Authentic South Indian Taste &nbsp;<span>*</span>&nbsp; Expert Culinary Team &nbsp;<span>*</span>&nbsp; Uncompromising Quality &nbsp;<span>*</span>&nbsp; Readymade Products &nbsp;<span>*</span>&nbsp; Authentic Flavors &nbsp;<span>*</span>&nbsp; Authentic South Indian Taste &nbsp;<span>*</span>&nbsp; Expert Culinary Team &nbsp;<span>*</span>&nbsp; Uncompromising Quality &nbsp;<span>*</span>&nbsp; Readymade Products &nbsp;<span>*</span>&nbsp; Authentic Flavors &nbsp;<span>*</span>&nbsp; Authentic South Indian Taste &nbsp;<span>*</span>&nbsp; Expert Culinary Team &nbsp;<span>*</span>&nbsp; Uncompromising Quality &nbsp;<span>*</span>&nbsp; Readymade Products &nbsp;<span>*</span>&nbsp; Authentic Flavors &nbsp;<span>*</span>&nbsp;</h3>
            </div>
          </div>

          <div className='readymade container'>
            {/* <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-RM-decoration.png' className='decoration' alt='Vivikta-Readymade-Decoration'/> */}
            <div className='content'>
              <span className='subheading'>VIVIKTA READYMADE FOOD</span>
              <h2>Delicious food <br/>made effortless</h2>
              <p className='r'>Discover effortless, mouthwatering meals <br className='desk-br'/>that bring flavor to your kitchen. Your <br className='desk-br'/>invite to a tasty feast.</p>
              <a href='/our-products' className='button'>VIEW ALL PRODUCT</a>
            </div>
            <div className='card-parent'>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-RM-GiC.png' className='card-img' alt='Readymade-Product'/>
                <h4>Ginger Chapathi</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-RM-MC.png' className='card-img' alt='Readymade-Product'/>
                <h4>Mint Chapathi</h4>
              </div>
              <div className='card'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-RM-GaC.png' className='card-img' alt='Readymade-Product'/>
                <h4>Garlic Chapathi</h4>
              </div>
            </div>
          </div>

          <div className='section2 bhavan container'>
            <div className='section2-inner'>
              <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam2.png' className='decoration1' alt='Vivikta-Catering-Decoration'/>
              <div className='container content'>
                <span className='subheading'>VIVIKTA BHAVAN</span>
                <h2>Every dish, a veggie <br/>masterpiece</h2>
                <p className='r'>Our culinary team takes great care to prepare each <br className='desk-br'/>dish to perfection and tailor it to meet the diverse <br className='desk-br'/>tastes and dietary needs of everyone.</p>
                <a href='/bhavan' className='button'>EXPLORE BHAVAN</a>
              </div>
              <div className="marquee-bhavan">
                <div className="track-bhavan">
                  <div className="content-bhavan">
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C3.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C4.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C5.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C6.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />

                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C3.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C4.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C5.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C6.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                  </div>
                </div>
              </div>
              <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-decoration.png' className='decoration-bhavan' alt='Vivikta-Catering-Decoration'/> 
            </div>
          </div>

          <div className="marquee">
            <div className="track">
              <h3 className="content">&nbsp;Authentic South Indian Taste &nbsp;<span>*</span>&nbsp; Expert Culinary Team &nbsp;<span>*</span>&nbsp; Uncompromising Quality &nbsp;<span>*</span>&nbsp; Readymade Products &nbsp;<span>*</span>&nbsp; Authentic Flavors &nbsp;<span>*</span>&nbsp; Authentic South Indian Taste &nbsp;<span>*</span>&nbsp; Expert Culinary Team &nbsp;<span>*</span>&nbsp; Uncompromising Quality &nbsp;<span>*</span>&nbsp; Readymade Products &nbsp;<span>*</span>&nbsp; Authentic Flavors &nbsp;<span>*</span>&nbsp; Authentic South Indian Taste &nbsp;<span>*</span>&nbsp; Expert Culinary Team &nbsp;<span>*</span>&nbsp; Uncompromising Quality &nbsp;<span>*</span>&nbsp; Readymade Products &nbsp;<span>*</span>&nbsp; Authentic Flavors &nbsp;<span>*</span>&nbsp;</h3>
            </div>
          </div>

          <div className='container our-story'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='content'>
                  <span className='subheading'>OUR STORY</span>
                  <h2>Best quality <br/>without compromise</h2>
                  <p className='r'>Since 2017, we started with a clear goal. To offer really good <br className='desk-br'/>products at prices that make sense for everyone, and <br className='desk-br'/>we've stuck to that promise. Today, we're still all about <br className='desk-br'/>giving you the best quality without compromise.</p>
                  <a href='/about' className='button'>READ OUR STORY</a>
                </div> 
              </div>
              <div className='col-lg-6'>
                <img className='our-story-img img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-About-4.png' alt='Vivikta Bhavan Store'/>
              </div>
            </div>
          </div>

          <div className='section2 testimonials-sec container'>
            <div className='section2-inner'>
              <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam.png' className='decoration1' alt='Vivikta-Catering-Decoration'/> 
              <div className='container content'>
                <span className='subheading'>TESTIMONIALS</span>
                <h2>What people say <br className='mob-br'/>about us</h2>
              </div>
              <div className="marquee-testimonial testimo-home">
                <div className="track-testimonial1">
                  <div className="content-testimonial">
                    <div className='testimonial-card'>
                      <h5>YOGESH SAID</h5>
                      <p className='s'>Through Google search only, I got the service of this food shop.  I ordered food for 60 people only to small birthday party.  The food are very delicious and tasty,  apart from this, the quantity are also more sufficient to us.  The price of the food is also very reasonable.  We are all happy.  Thank you Vivikta.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SRINIVASAN SAID</h5>
                      <p className='s'>Tasty food at reasonable prices, and neatly packed, well cooked.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SATHISH SAID</h5>
                      <p className='s'>We were dependent on Vivikta Chapati Shop's food for almost 7 to 8 weeks during the lockdown. Excellent homely food. Appreciate them following all the required protocols, during the lockdown and delivering food in emergencies. I love the varieties, quantity, quality, and reasonable pricing.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>VIDYASHRI SAID</h5>
                      <p className='s'>Very economical and good quality food... as we have been buying here for almost a year,  every week atleast once and never have we got any food related health upset issue .....never seen such a reasonable pricing for such good quality.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>RATHNA SAID</h5>
                      <p className='s'>Finding a healthy millet-based Chapati’s and side dishes was tough. Mr. Senthil of Vivikta
    Chapati Shop is very friendly and he suggested to us what options to go for, as there were several seniors in our gathering. Yummy food. The kambu chapatis were very tasty.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SK SAID</h5>
                      <p className='s'>Nice vegetarian options for family functions and parties. A friend referred and we hired them for my son’s poonal. The food was tasty and prepared nicely. Thank you.</p>
                    </div>

                    <div className='testimonial-card'>
                      <h5>YOGESH SAID</h5>
                      <p className='s'>Through Google search only, I got the service of this food shop.  I ordered food for 60 people only to small birthday party.  The food are very delicious and tasty,  apart from this, the quantity are also more sufficient to us.  The price of the food is also very reasonable.  We are all happy.  Thank you Vivikta.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SRINIVASAN SAID</h5>
                      <p className='s'>Tasty food at reasonable prices, and neatly packed, well cooked.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SATHISH SAID</h5>
                      <p className='s'>We were dependent on Vivikta Chapati Shop's food for almost 7 to 8 weeks during the lockdown. Excellent homely food. Appreciate them following all the required protocols, during the lockdown and delivering food in emergencies. I love the varieties, quantity, quality, and reasonable pricing.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>VIDYASHRI SAID</h5>
                      <p className='s'>Very economical and good quality food... as we have been buying here for almost a year,  every week atleast once and never have we got any food related health upset issue .....never seen such a reasonable pricing for such good quality.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>RATHNA SAID</h5>
                      <p className='s'>Finding a healthy millet-based Chapati’s and side dishes was tough. Mr. Senthil of Vivikta
    Chapati Shop is very friendly and he suggested to us what options to go for, as there were several seniors in our gathering. Yummy food. The kambu chapatis were very tasty.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SK SAID</h5>
                      <p className='s'>Nice vegetarian options for family functions and parties. A friend referred and we hired them for my son’s poonal. The food was tasty and prepared nicely. Thank you.</p>
                    </div>

                    <div className='testimonial-card'>
                      <h5>YOGESH SAID</h5>
                      <p className='s'>Through Google search only, I got the service of this food shop.  I ordered food for 60 people only to small birthday party.  The food are very delicious and tasty,  apart from this, the quantity are also more sufficient to us.  The price of the food is also very reasonable.  We are all happy.  Thank you Vivikta.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SRINIVASAN SAID</h5>
                      <p className='s'>Tasty food at reasonable prices, and neatly packed, well cooked.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SATHISH SAID</h5>
                      <p className='s'>We were dependent on Vivikta Chapati Shop's food for almost 7 to 8 weeks during the lockdown. Excellent homely food. Appreciate them following all the required protocols, during the lockdown and delivering food in emergencies. I love the varieties, quantity, quality, and reasonable pricing.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>VIDYASHRI SAID</h5>
                      <p className='s'>Very economical and good quality food... as we have been buying here for almost a year,  every week atleast once and never have we got any food related health upset issue .....never seen such a reasonable pricing for such good quality.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>RATHNA SAID</h5>
                      <p className='s'>Finding a healthy millet-based Chapati’s and side dishes was tough. Mr. Senthil of Vivikta
    Chapati Shop is very friendly and he suggested to us what options to go for, as there were several seniors in our gathering. Yummy food. The kambu chapatis were very tasty.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SK SAID</h5>
                      <p className='s'>Nice vegetarian options for family functions and parties. A friend referred and we hired them for my son’s poonal. The food was tasty and prepared nicely. Thank you.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="marquee-testimonial testimo-home">
                <div className="track-testimonial2">
                  <div className="content-testimonial">
                    <div className='testimonial-card'>
                      <h5>RATHNA SAID</h5>
                      <p className='s'>Finding a healthy millet-based Chapati’s and side dishes was tough. Mr. Senthil of Vivikta
    Chapati Shop is very friendly and he suggested to us what options to go for, as there were several seniors in our gathering. Yummy food. The kambu chapatis were very tasty.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>YOGESH SAID</h5>
                      <p className='s'>Through Google search only, I got the service of this food shop.  I ordered food for 60 people only to small birthday party.  The food are very delicious and tasty,  apart from this, the quantity are also more sufficient to us.  The price of the food is also very reasonable.  We are all happy.  Thank you Vivikta.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>MAGUDI SAID</h5>
                      <p className='s'>It was a sudden plan, but Vivikta Catering Service could quickly fill in for our requirements. Also, the cost was budget-friendly. We all enjoyed the starters.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SUNDAR SAID</h5>
                      <p className='s'>It's a take away shop. Idly was good. Rice and noodles were worth it. Recommended.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>TAMIL MARAN SAID</h5>
                      <p className='s'>Nice to eat. We have been eating for more than 2 years. Very good naturally prepared like our home. Jesus our Lord bless the staff and owner.</p>
                    </div>

                    <div className='testimonial-card'>
                      <h5>RATHNA SAID</h5>
                      <p className='s'>Finding a healthy millet-based Chapati’s and side dishes was tough. Mr. Senthil of Vivikta
    Chapati Shop is very friendly and he suggested to us what options to go for, as there were several seniors in our gathering. Yummy food. The kambu chapatis were very tasty.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>YOGESH SAID</h5>
                      <p className='s'>Through Google search only, I got the service of this food shop.  I ordered food for 60 people only to small birthday party.  The food are very delicious and tasty,  apart from this, the quantity are also more sufficient to us.  The price of the food is also very reasonable.  We are all happy.  Thank you Vivikta.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>MAGUDI SAID</h5>
                      <p className='s'>It was a sudden plan, but Vivikta Catering Service could quickly fill in for our requirements. Also, the cost was budget-friendly. We all enjoyed the starters.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SUNDAR SAID</h5>
                      <p className='s'>It's a take away shop. Idly was good. Rice and noodles were worth it. Recommended.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>TAMIL MARAN SAID</h5>
                      <p className='s'>Nice to eat. We have been eating for more than 2 years. Very good naturally prepared like our home. Jesus our Lord bless the staff and owner.</p>
                    </div>

                    <div className='testimonial-card'>
                      <h5>RATHNA SAID</h5>
                      <p className='s'>Finding a healthy millet-based Chapati’s and side dishes was tough. Mr. Senthil of Vivikta
    Chapati Shop is very friendly and he suggested to us what options to go for, as there were several seniors in our gathering. Yummy food. The kambu chapatis were very tasty.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>YOGESH SAID</h5>
                      <p className='s'>Through Google search only, I got the service of this food shop.  I ordered food for 60 people only to small birthday party.  The food are very delicious and tasty,  apart from this, the quantity are also more sufficient to us.  The price of the food is also very reasonable.  We are all happy.  Thank you Vivikta.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>MAGUDI SAID</h5>
                      <p className='s'>It was a sudden plan, but Vivikta Catering Service could quickly fill in for our requirements. Also, the cost was budget-friendly. We all enjoyed the starters.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>SUNDAR SAID</h5>
                      <p className='s'>It's a take away shop. Idly was good. Rice and noodles were worth it. Recommended.</p>
                    </div>
                    <div className='testimonial-card'>
                      <h5>TAMIL MARAN SAID</h5>
                      <p className='s'>Nice to eat. We have been eating for more than 2 years. Very good naturally prepared like our home. Jesus our Lord bless the staff and owner.</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='follow-us'>
                <span className='subheading'>Follow us</span>
                <h2>Keep in touch with us</h2>
                <div className="marquee-bhavan">
                  <a href='/#'><span>Instagram</span></a>
                  <div className="track-bhavan">
                    <div className="content-bhavan">
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C6.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C4.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C5.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C3.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />

                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C6.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C4.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C5.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C3.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Home-VB-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    </div>
                  </div>
                </div>
          </div>

        </main>
        <Footer/>
      </div>
    )

  }
}
