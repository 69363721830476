import React from 'react'

function Thanks() {
  return (
    <div className='thankspage'>
      <h1>Thanks for reaching out to us!</h1>
      <p>We will reach out to you shortly.</p>
      <a href='/' className='button'>BACK TO HOME</a>
    </div>
  )
}

export default Thanks