import React, { Component } from 'react'

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import emailjs from '@emailjs/browser';




export default class Contact extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      contactname: '',
      contactemail: '',
      contactnumber: '',
      contactmessage: '',
      error: {},
      submitted: false, // Added state to track form submission
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      try {
        const { contactname, contactnumber, contactemail,contactmessage } = this.state;

        // Create the template parameters for EmailJS
        const templateParams = {
          contactname,
          contactemail,
          contactnumber,
          contactmessage,
        };

        // Update the state to indicate form submission
        this.setState({ submitted: true });

        // Change the button text to "Sending"
        const submitButton = document.getElementById('contact-submit-button');
        if (submitButton) {
          submitButton.innerText = 'Sending...';
        }

        // Send the email using EmailJS
        await emailjs.send('service_own0qfp', 'template_iersa2k', templateParams, 'qYVbQvgskpsaPaEjV');
        console.log('Email sent successfully!');
        window.location.href = '/thanks';
        // Update the state to indicate form submission and trigger redirection
        this.setState({ submitted: true });
      } catch (error) {
        console.log('Error sending the form:', error);
      }
    }
  };

  validateForm = () => {
    const { contactname, contactemail, contactnumber } = this.state;
    let isValid = true;
    let error = {};

    if (!contactname) {
      isValid = false;
      error.contactname = 'Name is required';
    }

    if (!contactemail) {
      isValid = false;
      error.contactemail = 'Email is required';
    } else if (!this.validateEmail(contactemail)) {
      isValid = false;
      error.contactemail = 'Invalid email address';
    }

    if (!contactnumber) {
      isValid = false;
      error.contactnumber = 'Phone number is required';
    } else if (!this.validatePhoneNumber(contactnumber)) {
      isValid = false;
      error.contactnumber = 'Invalid phone number';
    }


    this.setState({ error });
    return isValid;
  };

  validateEmail = (contactemail) => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(contactemail);
  };

  validatePhoneNumber = (contactnumber) => {
    // Phone number validation regex pattern
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(contactnumber);
  };


  render() {

    const { contactname, contactemail, contactnumber, contactmessage, error } = this.state;
  
    return (
      <div className='cateringpage contactpage1'>
        <style>
          {"\
          header a.contacta, header a.contacta:hover{\
            background-color: var(--primary-color);\
            color: var(--background-color);\
            text-decoration: none !important;\
          }\
          "}
        </style>
        <Header/>
        <main>
          <div className='container hero'>
            <div className='row'>
              <div className='col-lg-7 hcontent'>
                  <span className='subheading'>Contact us</span>
                  <h2>Let’s discuss your needs.</h2>
                  <div className='details-cpage'>
                    <h4>Our Location</h4>
                    <p className='s'>Senthil Andavar Street, Karunanidhi Salai, <br className="desk-br"/>Dhanalakshmi Colony, Vadapalani,<br className="desk-br"/> Chennai, Tamil Nadu 600026</p>
                  </div>
                  <div className='details-cpage'>
                    <h4>Call Us</h4>
                    <p className='s'><a target='blank' href='tel:8338838830'>+91 - 9941434565</a></p>
                  </div>
                  <div className='details-cpage'>
                    <h4>Email Us</h4>
                    <p className='s'><a target='blank' href='mailto:viviktagroups@gmail.com'>viviktagroups@gmail.com</a></p>
                  </div>
              </div>
              <div className='col-lg-5 form-side  hform'>
                <h4 className='mob-br mobileheading'>Get in touch</h4>
                <form onSubmit={this.handleSubmit}>
                  <input type="text" id="contactname" name="contactname" value={contactname} onChange={this.handleChange} placeholder='Your Name' required/>
                  {error.contactname && <span className="error">{error.contactname}</span>}

                  <input type="number" name="contactnumber" id="contactnumber" value={contactnumber} onChange={this.handleChange}placeholder='Your Phone Number' required/>
                  {error.contactnumber && <span className="error">{error.contactnumber}</span>}

                  <input type="email" name="contactemail" id="contactemail" value={contactemail} onChange={this.handleChange}  placeholder='Your Email address' required/>
                  {error.contactemail && <span className="error">{error.contactemail}</span>}

                  <textarea name="contactmessage" value={contactmessage} onChange={this.handleChange}placeholder='Your Message'/>

                  <input type="submit" value="SEND ENQUIRY" className='button' id='contact-submit-button' />
                </form>
              </div>
            </div>
          </div>
        </main>
        <Footer/>
      </div>
    )

  }
}
