import React from 'react'

function Lost() {
  return (
    <div className='lostpage'>
      <img className='img-fluid' src='https://ik.imagekit.io/vivikta/website/tr:f-auto/404.png' alt="404" />
      <a href='/' className='button'>BACK TO HOME</a>
    </div>
  )
}

export default Lost