import React, { Component } from 'react'
import Lenis from '@studio-freight/lenis'


export default class Header extends Component {

  render() {

    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), 
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })

    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }


    requestAnimationFrame(raf)

    return (
      <div className='header-cover'>
          <header>
            <div className='container'>
              <div className='row d-none d-lg-flex'>
                <div className='col-lg-2 left'>
                  <a href='/'><img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Vivikta-Logo.png' className="logo" alt='Vivikta Logo'/></a>
                </div>
                <div className='col-lg-8 middle'>
                  <a href='/' className='d-menu-item homea'>Home</a>
                  <a href='/our-products' className='d-menu-item productsa'>Our Products</a>
                  <a href='/catering' className='d-menu-item cateringa'>Catering</a>
                  <a href='/bhavan' className='d-menu-item bhavana'>Bhavan</a>
                  <a href='/about' className='d-menu-item abouta'>Our Story</a>
                </div>
                <div className='col-lg-2 right'>
                  <a href='/contact' className="button">CONTACT US</a>
                </div>
              </div>
              <div className='row d-flex d-lg-none'>
                <div className='col-6 left'>
                  <a href='/'><img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/Vivikta-Logo.png' className="logo" alt='Vivikta Logo'/></a>
                </div>

                <div className='col-6 right'>
                  <nav role="navigation">
                    <div id="menuToggle">
                      <input type="checkbox" />
                      <span></span>
                      <span></span>
                      <span></span>
                      <ul id="menu">
                        <a href='/' className='m-menus homea'>Home</a>
                        <a href='/our-products' className='m-menus productsa'>Our Products</a>
                        <a href='/catering' className='m-menus cateringa'>Catering</a>
                        <a href='/bhavan' className='m-menus bhavana'>Bhavan</a>
                        <a href='/about' className='m-menus abouta'>Our Story</a>
                        <a href='/contact' className='m-menus contacta'>Contact Us</a>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </header>
      </div>
    )
  }
}
