import React, { Component } from 'react'

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import emailjs from '@emailjs/browser';
// import Breakfastmenu from '../menu/breakfastmenu';
// import Lunchmenu from '../menu/lunchmenu';
// import Dinnermenu from '../menu/dinnermenu';




export default class Catering extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      cateringname: '',
      cateringnumber: '',
      cateringtype:'',
      cateringmenu:'',
      cateringlocation: '',
      error: {},
      submitted: false, // Added state to track form submission
    };
  }
  


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      try {
        const { cateringname, cateringnumber, cateringtype, cateringmenu, cateringlocation } = this.state;

        // Create the template parameters for EmailJS
        const templateParams = {
          cateringname,
          cateringnumber,
          cateringtype,
          cateringmenu,
          cateringlocation
        };

        // Update the state to indicate form submission
        this.setState({ submitted: true });

        // Change the button text to "Sending"
        const submitButton = document.getElementById('catering-submit-button');
        if (submitButton) {
          submitButton.innerText = 'Sending...';
        }

        // Send the email using EmailJS
        await emailjs.send('service_own0qfp', 'template_206ao7g', templateParams, 'qYVbQvgskpsaPaEjV');
        console.log('Email sent successfully!');
        window.location.href = '/thanks';
        // Update the state to indicate form submission and trigger redirection
        this.setState({ submitted: true });
      } catch (error) {
        console.log('Error sending the form:', error);
      }
    }
  };

  validateForm = () => {
    const { cateringname, cateringnumber, cateringtype, cateringmenu, cateringlocation } = this.state;
    let isValid = true;
    let error = {};

    if (!cateringname) {
      isValid = false;
      error.cateringname = 'Name is required';
    }

    if (!cateringnumber) {
      isValid = false;
      error.cateringnumber = 'Phone number is required';
    } else if (!this.validatePhoneNumber(cateringnumber)) {
      isValid = false;
      error.cateringnumber = 'Invalid phone number';
    }

    if (!cateringtype) {
      isValid = false;
      error.cateringtype = 'Event type is required';
    }
    
    if (!cateringmenu) {
      isValid = false;
      error.cateringmenu = 'Catering menu is required';
    }

    if (!cateringlocation) {
      isValid = false;
      error.cateringlocation = 'Location is required';
    }


    this.setState({ error });
    return isValid;
  };


  validatePhoneNumber = (cateringnumber) => {
    // Phone number validation regex pattern
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(cateringnumber);
  };


  render() {

    const { cateringname, cateringnumber, cateringtype, cateringmenu, cateringlocation, error } = this.state;
  
    return (
      <div className='cateringpage'>
        <style>
          {"\
          header a.cateringa, header a.cateringa:hover{\
            background-color: var(--primary-color);\
            color: var(--background-color);\
            text-decoration: none !important;\
          }\
          "}
        </style>
        <Header/>
        <main>
          <div className='container hero'>
            <div className='row'>
              <div className='col-lg-7 hcontent'>
                <span className='subheading'>Catering</span>
                <h2>Explore the <br className='desk-br'/>infinite possiblities</h2>
                <p className='r'>Whether it's a corporate gathering, a family <br className='desk-br'/>celebration, or any special occasion, let us take <br className='desk-br'/>care of the catering so you can focus on <br className='desk-br'/>enjoying the moment.</p>
                <a href='/contact' className='button'>CONTACT US</a>
              </div>
              <div className='col-lg-5 hform' id='custom-menu'>
                <h3>Tell us your needs</h3>
                <form onSubmit={this.handleSubmit}>
                  <input type="text" id="cateringname" name="cateringname" value={cateringname} onChange={this.handleChange} placeholder='Your Name' required/>
                  {error.cateringname && <span className="error">{error.cateringname}</span>}

                  <input type="number" name="cateringnumber" id="cateringnumber" value={cateringnumber} onChange={this.handleChange}placeholder='Your Phone Number' required/>
                  {error.cateringnumber && <span className="error">{error.cateringnumber}</span>}

                  <select id="cateringtype" name="cateringtype" value={cateringtype} onChange={this.handleChange} required>
                    <option value="">Event Type</option>
                    <option value="Marriage">Marriage</option>
                    <option value="Engagement">Engagement</option>
                    <option value="Corporate order">Corporate order</option>
                    <option value="House warming">House warming</option>
                    <option value="Birthday">Birthday</option>
                    <option value="Baby Shower">Baby Shower</option>
                    <option value="Others">Others</option>
                  </select>
                  {error.cateringtype && <span className="error">{error.cateringtype}</span>}

                  <select id="cateringmenu" name="cateringmenu" value={cateringmenu} onChange={this.handleChange} required>
                    <option value="">Select Menu</option>
                    <option value="Breakfast Menu 1">Breakfast Menu 1</option>
                    <option value="Breakfast Menu 2">Breakfast Menu 2</option>
                    <option value="Breakfast Menu 3">Breakfast Menu 3</option>
                    <option value="Breakfast Menu 4">Breakfast Menu 4</option>
                    <option value="Lunch Menu 1">Lunch Menu 1</option>
                    <option value="Lunch Menu 2">Lunch Menu 2</option>
                    <option value="Lunch Menu 3">Lunch Menu 3</option>
                    <option value="Lunch Menu 4">Lunch Menu 4</option>
                    <option value="Lunch Menu 5">Lunch Menu 5</option>
                    <option value="Dinner Menu 1">Dinner Menu 1</option>
                    <option value="Dinner Menu 2">Dinner Menu 2</option>
                    <option value="Dinner Menu 3">Dinner Menu 3</option>
                    <option value="Dinner Menu 4">Dinner Menu 4</option>
                    <option value="Dinner Menu 5">Dinner Menu 5</option>
                    <option value="Custom Menu">Custom Menu</option>
                  </select>
                  {error.cateringmenu && <span className="error">{error.cateringmenu}</span>}

                  <input type="text" id="cateringlocation" name="cateringlocation" value={cateringlocation} onChange={this.handleChange} placeholder='Location' required/>
                  <input type="submit" value="SEND ENQUIRY" className='button' id='catering-submit-button' />
                </form>
              </div>
            </div>
          </div>

          <div className='home'>
            <div className='section2 catering container'>
              <div className='section2-inner'>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-thooranam.png' className='decoration1' alt='Vivikta-Catering-Decoration'/> 
                <div className='container row'>
                  <div className='col-lg-6 content' id='view-menu'>
                    <h3>Catering for your <br/>weddings & events</h3>
                    <p className='r'>At Vivikta catering, we bring passion and innovation to <br className='desk-br'/>the art of catering. Our wonderful team of culinary <br className='desk-br'/>experts is dedicated to curating unforgettable dining <br className='desk-br'/>experiences tailored to your unique preferences.</p>
                  </div>
                  {/* <div className='col-lg-6' id='view-menu'>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Breakfast Menu
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <Breakfastmenu/>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Lunch Menu
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <Lunchmenu/>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Dinner Menu
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <Dinnermenu/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-veg-catering.png' className='decoration-banana-leaf' alt='Vivikta-Catering-Decoration'/> 
              </div>
            </div>

            <div className='follow-us'>
                <h3>Make your event with best <br className='desk-br'/>catering menu</h3>
                <p className='r'>One of our standout features is our delightful array of sweet items. With over 100+ <br className='desk-br'/>varieties to choose from, your guests will be treated to a sweet symphony of <br className='desk-br'/>flavours and textures. Our sweet delicacies are crafted with love and attention to <br className='desk-br'/>detail, ensuring that every bite is a moment of pure indulgence.</p>
                <a href='#custom-menu' className='button'>GET IN TOUCH</a>
                <div className="marquee-bhavan">
                  <div className="track-bhavan">
                    <div className="content-bhavan">
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C3.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C4.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />

                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C3.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C4.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C1.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                      <img src='https://ik.imagekit.io/vivikta/website/tr:f-auto/V-Catering-C2.png' className='bhavan-card img-fluid' alt='Vivkta Bhavan Menu' />
                    </div>
                  </div>
                </div>
          </div>
          </div>

        </main>
        <Footer/>
      </div>
    )

  }
}
